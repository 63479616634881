import { useCallback, useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import { ID } from '../../../../../../../types';

import { useFinPaginatedTeams } from '../../../../../hooks/useFinPaginatedTeams';

import {
  FETCH_TEAMS_FILTER_QUERY,
  FetchTeamsFilterQueryResponse
} from '../../../../../queries/teamsFilter.query';

interface TeamsFilterOptions {
  teamType: string;
  value: ID[];
}

function useTeamsFilter({ teamType, value }: TeamsFilterOptions) {
  const { teams, teamsError, teamsLoading, filterTeams } =
    useFinPaginatedTeams<FetchTeamsFilterQueryResponse>({
      cacheKey: `${teamType}-teams`,
      query: FETCH_TEAMS_FILTER_QUERY,
      initialFilters: { teamType: { eq: teamType } },
      initialLimit: 100
    });

  const {
    teams: selectedTeams,
    teamsError: selectedTeamsError,
    teamsFetched: selectedTeamsFetched,
    teamsLoading: selectedTeamsLoading,
    filterTeams: filterSelectedTeams
  } = useFinPaginatedTeams<FetchTeamsFilterQueryResponse>({
    cacheKey: `selected-${teamType}-teams`,
    query: FETCH_TEAMS_FILTER_QUERY,
    initialFilters: { teamType: { eq: teamType }, nanoId: { in: value } },
    initialLimit: 100
  });

  const selectedValue = useMemo(() => {
    return selectedTeams?.map(({ nanoId: value, name: label }) => ({
      value,
      label
    }));
  }, [selectedTeams]);

  useEffect(() => {
    if (isEqual(value, map(selectedTeams, 'nanoId')) || selectedTeamsLoading) {
      return;
    }

    filterSelectedTeams({ nanoId: { in: value } });
  }, [value, filterSelectedTeams, selectedTeams, selectedTeamsLoading]);

  const handleFilterTeams = useCallback(
    (teamName: string) =>
      filterTeams({
        teamType: { eq: teamType },
        name: { ilike: teamName }
      }),
    [filterTeams, teamType]
  );

  const data = useMemo(() => {
    return teams?.map(({ nanoId: value, name: label }) => ({ value, label }));
  }, [teams]);

  return {
    data,
    handleFilterTeams,
    selectedTeamsFetched,
    teamsLoading,
    selectedValue,
    errorMessage: teamsError || selectedTeamsError
  };
}

export default useTeamsFilter;
